import { render, staticRenderFns } from "./HomeSNS.vue?vue&type=template&id=532d7fb8&scoped=true"
import script from "./HomeSNS.vue?vue&type=script&lang=js"
export * from "./HomeSNS.vue?vue&type=script&lang=js"
import style0 from "./HomeSNS.vue?vue&type=style&index=0&id=532d7fb8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "532d7fb8",
  null
  
)

export default component.exports