<template>
   <div class="information">
    <div class="content">
      <div class="component-title">病院からのお知らせ</div>
      <!-- <div class="component-title">院長からのお知らせ</div> -->
    </div>
    <div class="inf-request">
      <div class="request" v-for="item in informationList" @click.prevent="getInfo(item.id)">
        <el-link :underline="false" :href="`https://www.tenyukai-hope.com/information?id=${item.id}`">
          <span class="date">{{ item.createTime }}</span>
          <span class="content">{{ item.title }}</span>
        </el-link>
      </div>
      <div class="to-inf" @click.prevent="goTo('/information')"><el-link :underline="false" href="https://www.tenyukai-hope.com/information">お知らせ一覧へ</el-link></div>
    </div>
  </div>
</template>

<script>

import moment from "moment";

export default {
  name: "HomeInformation",
  data() {
    return {
      informationList: []
    }
  },
  created() {
    axios.get(`${this.GLOBAL.host}/t/info/all`).then(resp => {
      // 按时间排序
      resp.data.sort((a, b) => {
        let t1 = new Date(a.createTime)
        let t2 = new Date(b.createTime)
        return t2.getTime() - t1.getTime()
      })
      // 截取前五个
      resp.data = resp.data.slice(0, 5)
      resp.data.map(i => {
        i.createTime = moment(i.createTime).format('YYYY.MM.DD')
      })
      this.informationList = resp.data
    })
  },
  methods: {
    goTo(path) {
      this.$router.push(path)
    },
    getInfo(id) {
      this.$router.push({
        path: '/information',
        query: {id}
      })
    }
  }
}
</script>

<style scoped>
/*病院からのお知らせ*/
.component-title {
  background-color: #62b6e2;
  color: #FFFFFF;
  font-size: 18px;
  padding: 14px 30px;
  width: 30%;
}
.inf-request {
  border: 2px solid #62b6e2;
  padding: 30px;
}
.inf-request .request {
  border-bottom: 1px dashed #CCCCCC;
  overflow: hidden;  
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /*行数*/
  -webkit-box-orient: vertical;
  padding: 14px 0;
}
.inf-request .request a:hover span {
  color: #62b6e2;
}
.inf-request .content {
  margin-left: 30px;
  text-decoration: underline;
}
.to-inf {
  background-color: #62b6e2;
  border-radius: 5px;
  color: #FFFFFF;
  display: inline-block;
  font-size: 14px;
  text-align: left;
  margin-top: 30px;
  padding: 10px 20px;
  border: 1px solid #62b6e2;
  transition: 150ms;
}
.to-inf .el-link {
  color: #FFFFFF;
  transition: 150ms;
}
.to-inf:hover{
  background-color: #ffffff;
  color: #62b6e2;
  
  /* opacity: 0.8; */
}
.to-inf:hover .el-link {
  color: #62b6e2;
}
</style>
