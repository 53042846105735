<template>
  <!-- <el-carousel indicator-position="inside"
                            :height="carouselHeight+'px'"
                            trigger="click"
                            :interval="6000"> -->
        <el-carousel indicator-position="inside"
        trigger="click"
        :interval="6000">
          <el-carousel-item v-for="item in picNameList" :key="item.pic">
            <el-image id="carouselImage" :src="require(`@/assets/carousel/${item.pic}`)" :alt="`${item.alt}`" loading="lazy"/>
          </el-carousel-item>
        </el-carousel>
</template>

<script>

export default {
  name: "MSwiper",
  data() {
    return {
      picNameList: [
        {
          pic:'home_01.webp',
          alt:'のぞみクリニック築地',
        },
        {
          pic:'home_02.webp',
          alt:'診察室',
        },
        {
          pic:'home_03.webp',
          alt:'採血室',
        },
        {
          pic:'IMG_0054.webp',
          alt:'ヨウ素水',
        },
      ],
    }
  }
}
</script>

<style scoped>
.el-carousel__item.is-animating {
    -webkit-transition: -webkit-transform 2s ease-in-out;
    transition: -webkit-transform 2s ease-in-out;
    transition: transform 2s ease-in-out;
    transition: transform 2s ease-in-out,-webkit-transform 2s ease-in-out;
}
</style>
