<template>
<div class="departments">
  <el-row type="flex" justify="center">
    <el-col :md="20" :lg="18" :xl="15">
      <div style="width: 100%;max-width: 1284px; margin: 0 auto;">
        <!--2024-9-17 XH 备忘：部分新业务没有对应图标，考虑画新的，或者全删掉-->
        <div class="departments-wrap " style="margin-bottom: 75px;">
          <div class="item internal" @click="goDepartment('generalMedicine')">
            <!-- <div class="image"><img src="@/assets/departments/logo_internal.svg" alt="" height="20px" width="20px"></div> -->
             <div class="backColor"></div>
            <div class="department_title">
                <div class="text-ja">一般内科</div>
                <div class="separator"></div>
                <div class="text-en">General Medicine</div>
            </div>
          </div>
          <div class="item fever" @click="goDepartment('fever')" style="margin-left: 2.5%;">
            <!-- <div class="image"><img src="@/assets/departments/logo_internal.svg" alt="" height="20px" width="20px"></div> -->
            <div class="backColor"></div>
            <div class="department_title">
              <div class="text-ja">発熱外来</div>
              <div class="separator"></div>
              <div class="text-en">Fever Outpatient</div>
            </div>
          </div>
          <div class="item corona" @click="goDepartment('corona')" style="margin-left: 2.5%;">
            <!-- <div class="image"><img src="@/assets/departments/logo_kampo.svg" alt="" height="20px" width="20px"></div> -->
            <div class="backColor"></div>
            <div class="department_title">
              <div class="text-ja">コロナ予防接種</div>
              <div class="separator"></div>
              <div class="text-en">Corona Vaccination</div>
            </div>
          </div>
        </div>


        <div class="departments-wrap ">
          <div class="item diet" @click="goDepartment('diet')">
            <!-- <div class="image"><img src="@/assets/departments/logo_tumor.svg" alt="" height="20px" width="20px"></div> -->
            <div class="backColor"></div>
            <div class="department_title">
              <div class="text-ja">メディカルダイエット</div>
              <div class="separator"></div>
              <div class="text-en">Reduce Weight</div>
            </div>
          </div>
          
          <div class="item placenta" @click="goDepartment('placenta')" style="margin-left: 2.5%;">
            <!-- <div class="image"><img src="@/assets/departments/logo_second.svg" alt="" height="20px" width="20px"></div> -->
            <div class="backColor"></div>
            <div class="department_title">
              <div class="text-ja">プラセンタ</div>
              <div class="separator"></div>
              <div class="text-en">Placenta </div>
            </div>
          </div>
          <div class="item Stemsup" @click="goDepartment('stemsup')" style="margin-left: 2.5%;">
            <!-- <div class="image"><img src="@/assets/departments/logo_placenta.svg" alt="" height="20px" width="20px"></div> -->
            <div class="backColor"></div>
            <div class="department_title">
              <div class="text-ja">エクソソーム</div>
              <div class="separator"></div>
              <div class="text-en">Exosome Infusion</div>
            </div>
          </div>
        </div>
      </div>
    </el-col>
  </el-row>
</div>
</template>

<script>
export default {
  name: "HomeDepartmentsExperiment",
  methods: {
    goDepartment(name) {
      this.$router.push(`/department/${name}`)
    },
    toUrl(){
      window.open('https://www.tenyukai-hope.com','_self')
    }
  }
}
</script>

<style scoped>
.departments {
  margin: 0 auto;
  padding: 40px 0;
}
.departments-wrap {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 200px;
}
.item {
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px #CCCCCC;
  border-radius: 5px;
  cursor: pointer; /*鼠标放上去，变成手的形状*/
  text-align: center;
  margin-top: 20px;
  padding: 20px 10px;
  width: 30%;
  height: 100%;
}
.item .text-ja {
  font-size: 25px;
  color: #FFFFFF;
  margin-top: 14px;
  font-weight: bold;
  transition: 300ms;
}
.item .text-en {
  font-size: 14px;
  color: #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}

/*鼠标放上面前后变化*/
/* 胃腸科 */
.internal {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/gm_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.internal .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.internal .text-en>span {
  color: #FFFFFF;
}
.internal:hover, .internal:hover .text-ja, .internal:hover .text-en, .internal:hover .text-en>span {
  color: #f37878;
}
.internal:hover .separator {
  border-top: 1px solid #f37878;
}
.internal .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  transition:150ms;
}
.internal .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #eb3d3db0;
  transition:150ms;

}
.internal:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;
}
.internal:hover .department_title{
  background-color: #ffffff00;
}


/* 漢方内科 */
.fever {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/fever_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.fever .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.fever .text-en>span {
  color: #FFFFFF;
}
.fever:hover, .fever:hover .text-ja, .fever:hover .text-en, .fever:hover .text-en>span {
  
  color: #12cda4;
}
.fever:hover .separator {
  border-top: 1px solid #12cda4;
}
.fever .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  transition:150ms;
}
.fever .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #12cda4b0;
  transition:150ms;

}
.fever:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;
}
.fever:hover .department_title{
  background-color: #ffffff00;
}


/*CORONA*/
.corona {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/corona_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.corona .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.corona .text-en>span {
  color: #FFFFFF;
}
.corona:hover, .corona:hover .text-ja, .corona:hover .text-en, .corona:hover .text-en>span {
  
  color: #1cb7d6;
}
.corona:hover .separator {
  border-top: 1px solid #1cb7d6;
}
.corona .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  transition:150ms;
  /* background-color: #92c36000;  */
  /* mix-blend-mode: color; */
  

}
.corona .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #4acae4b0;
  transition:150ms;

}
.corona:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;
}
.corona:hover .department_title{
  background-color: #ffffff00;
}


/* 腫瘍外科 */
.diet {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/diet_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.diet .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.diet .text-en>span {
  color: #FFFFFF;
}
.diet:hover, .diet:hover .text-ja, .diet:hover .text-en, .diet:hover .text-en>span {
  color: #64ae1b;
}
.diet:hover .separator {
  border-top: 1px solid #64ae1b;
}

.diet .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  transition:150ms;
  /* background-color: #92c36000;  */
  /* mix-blend-mode: color; */
  

}
.diet .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #78cd23b0;
  transition:150ms;

}
.diet:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;
}
.diet:hover .department_title{
  background-color: #ffffff00;
}





/* セカンドオピニオン */
.placenta {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/placenta_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.placenta .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.placenta .text-en>span {
  color: #FFFFFF;
}
.placenta:hover, .placenta:hover .text-ja, .placenta:hover .text-en, .placenta:hover .text-en>span {
  
  color: #eba446;
}
.placenta:hover .separator {
  border-top: 1px solid #eba446;
}

.placenta .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  background-color: #70c9b600;
  mix-blend-mode: color;
  transition:150ms;

}
.placenta .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #eba446b0;
  transition:150ms;

}
.placenta:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;

}

.placenta:hover .department_title{
  background-color: #ffffff00;

}



/* ダイエット */
.Stemsup {
  position: relative;
  background-image: linear-gradient(270deg, #ffffff61,#ffffff61 75%), url(../../assets/departments/stemsup_home.webp);
  background-size: cover;
  color: #FFFFFF;
}
.Stemsup .separator {
  border-top: 1px solid #FFFFFF;
  margin-top: 14px;
  transition: 300ms;
}
.Stemsup .text-en>span {
  color: #FFFFFF;
}
.Stemsup:hover, .Stemsup:hover .text-ja, .Stemsup:hover .text-en, .Stemsup:hover .text-en>span {
  color: #7887d9;
}
.Stemsup:hover .separator {
  border-top: 1px solid #7887d9;
}

.Stemsup .backColor{
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
  left: 0px;
  top: 0px;
  background-color: #7887d900; 
  mix-blend-mode: color;
  transition:150ms;

}
.Stemsup .department_title{
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 50%;
  left: 0px;
  margin-top: 11%;
  padding-top: 3px;
  padding-bottom: 0px;
  background-color: #5b6fe5b0;
  transition:150ms;
}
.Stemsup:hover .backColor{
  background-color: #ffffffb4;
  mix-blend-mode: lighten;
}
.Stemsup:hover .department_title{
  background-color: #ffffff00;
}


.side-box{
  display: inline-flex;
  justify-content: space-between;
  align-items:center;
  vertical-align: top;
  height: auto;
}



</style>
